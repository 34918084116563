import './css/styles.css';
import Header from './Header.jsx'
import data from './data/data.json';
import jonesbootmaker from './images/jonesbootmakerNew.jpg';
import pavers from './images/pavers.jpg';
import paversfoundation from './images/paversfoundation.jpg';
import flyflot from './images/flyflot.jpg';
import letsgetjamesskinny from './images/skinny.jpg';
import etool from './images/EtoolPatient.jpg';
import bluesky from './images/bluesky.jpg';
import solotraxx from './images/solotraxx.jpg';

function App() {
  return (
    <div>
      <Header data={data}
              jonesbootmaker={jonesbootmaker}
              pavers={pavers}
              paversfoundation={paversfoundation}
              flyflot={flyflot}
              letsgetjamesskinny={letsgetjamesskinny}
              etool={etool}
              bluesky={bluesky}
              solotraxx={solotraxx}/>

    </div>
  );
}

export default App;
