import Background from './images/hero-img.jpg';
import parse from 'html-react-parser';

function Header(props) {
  return (
   <div>
 {/* HEADER */}
     <div className="uk-child-width-1 uk-grid uk-grid-stack" uk-grid="">
      <div className="uk-first-column">
        <div className="uk-height-large uk-panel uk-flex uk-flex-center uk-flex-middle bg-img" style={{backgroundImage: `url(${Background})` }}>
          <div className="uk-overlay uk-position-center uk-light" id="centerTop">
            <h1 className="uk-heading-primary uk-overlay uk-position-center uk-light" id="text-white">{props.data.header.title}</h1>
            <h4 className="uk-heading-divider uk-overlay uk-position-center uk-light section" id="text-grey">{props.data.header.job_title}</h4>
          </div>
          <div className="uk-overlay-primary uk-position-cover"></div>
        </div>
      </div>
     </div>
  {/* HEADER END */}
     <div className="uk-container uk-align-center uk-grid-margin uk-first-column">
        <div className="uk-child-width-1 uk-grid uk-grid-stack" uk-grid="">
          <article className="uk-align-center uk-article uk-first-column">
            <h1 className="uk-article-title"><a className="uk-link-reset" href="">{props.data.about_me_title}</a></h1>
            <p>{props.data.about_me}</p>
          </article>
      </div>
    </div>
    <div className="uk-container">
      <div className="uk-child-width-1-2@m uk-grid-small uk-grid-match uk-grid" uk-grid="">
        
          {Object.keys(props.data.design_development).map(key => {
        const design_development = props.data.design_development[key];
        return(
          <div>
          <div key={key} className="uk-card uk-card-hover uk-card-default uk-card-body">
            <span className="uk-icon-justify center-align uk-icon" uk-icon={`icon: ${design_development.svg}; ratio: 2`}>
            </span>
            <h3 className="uk-card-title center-align">{design_development.title}</h3>
            <p>{design_development.description}</p>
          </div>
          </div>
          );
      })} 
        
      </div>
    </div>
    <div className="uk-container uk-align-center uk-grid-margin uk-first-column">
    <h1 className="uk-article-title"><a className="uk-link-reset" href="">{props.data.featured_title}</a></h1>
<div className="uk-child-width-1-2@m uk-grid-small uk-grid-match uk-grid" uk-grid="">
  {Object.keys(props.data.work_examples).map(key => {
    const example = props.data.work_examples[key];

    let exampleLink = example.link;
    if (!example.title){
      exampleLink = "";
    }

    let srcImg = key.replace(/['"]+/g, '');
  
    return(
      <div key={key}>
        <div className="uk-card uk-card-hover uk-card-default uk-card-body">
          <div className="uk-card-media-top">
            <img src={props[srcImg]} alt={example.title} />
          </div>
          <h3 className="uk-card-title center-align">{example.title}</h3>
          <p>{example.information} <a src={example.link} target="_blank">{example.link}</a></p>
        </div>
      </div>
      );
  })}   
  </div>
  <div className="uk-child-width-1 uk-grid uk-grid-stack" uk-grid="">
    <article className="uk-align-center uk-article uk-first-column">
      <h1 className="uk-article-title">{props.data.experience_title}</h1>
      {Object.keys(props.data.my_experience).map(key => {
        const experience = props.data.my_experience[key];
        return(
          <p key={key} className="white-text">
          {experience}
          </p>
          );
      })}   
    </article>
  </div>
    </div>
      <footer className="uk-grid-margin uk-first-column uk-text-center">
        <div className="uk-tile uk-tile-muted">
          <div className="uk-container uk-align-center">
            <div className="uk-padding">
              <div className="uk-child-width-1 uk-grid uk-grid-stack" uk-grid="">
                <article className="uk-article uk-first-column">
                  <p>© {(new Date().getFullYear())} {props.data.header.title}&nbsp;&nbsp;&nbsp;<a href="https://www.linkedin.com/in/jonathan-wall-870531a5/" target="_blank" className="uk-icon-link linkedin uk-icon" uk-icon="linkedin"><svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" ratio="1"> <path d="M5.77,17.89 L5.77,7.17 L2.21,7.17 L2.21,17.89 L5.77,17.89 L5.77,17.89 Z M3.99,5.71 C5.23,5.71 6.01,4.89 6.01,3.86 C5.99,2.8 5.24,2 4.02,2 C2.8,2 2,2.8 2,3.85 C2,4.88 2.77,5.7 3.97,5.7 L3.99,5.7 L3.99,5.71 L3.99,5.71 Z"></path> <path d="M7.75,17.89 L11.31,17.89 L11.31,11.9 C11.31,11.58 11.33,11.26 11.43,11.03 C11.69,10.39 12.27,9.73 13.26,9.73 C14.55,9.73 15.06,10.71 15.06,12.15 L15.06,17.89 L18.62,17.89 L18.62,11.74 C18.62,8.45 16.86,6.92 14.52,6.92 C12.6,6.92 11.75,7.99 11.28,8.73 L11.3,8.73 L11.3,7.17 L7.75,7.17 C7.79,8.17 7.75,17.89 7.75,17.89 L7.75,17.89 L7.75,17.89 Z"></path></svg></a></p>
                </article>
              </div>
            </div>
          </div>
        </div>
      </footer>
   </div>
  );
}

export default Header;
